<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title
        v-if="!isImportInProgress"
      >
        <span class="headline">Import Contacts From Your Hubspot Account</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row
            v-if="!isImportInProgress"
          >
            <v-col
              cols="12"
            >
              <v-select
                v-model="field.groupId"
                :items="groups"
                label="Select a Group first *"
                item-text="name"
                item-value="id"
                required
                :error="!field.groupId && isFormDirty"
              />
            </v-col>
          </v-row>
          <div
            v-if="isImportInProgress"
            class="loaderWrapper"
          >
            <div class="lds-grid">
              <div /><div /><div /><div /><div /><div /><div /><div /><div />
            </div>
            <p>Importing contacts from Hubspot</p>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions
        v-if="!isImportInProgress"
      >
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!field.groupId"
          @click="startImport"
        >
          Start Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {
    name: 'HubspotImportDialog',
    props: {
      isVisible: {
        type: Boolean,
        default: true,
      },
      hubspotToken: {
        type: String,
        default: '',
      },
      username: {
        type: String,
        default: '',
      },
      groups: {
        type: Array,
        default: () => [],
      },
    },

    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      field: {
        groupId: '',
      },
      isImportInProgress: false,
    }),

    watch: {
      isVisible (val) {
        if (val) {
          // Opened
          this.field.groupId = ''
          this.isImportInProgress = false
        }
      },
    },

    methods: {
      close () {
        this.$emit('on-cancel')
      },

      async startImport () {
        this.isImportInProgress = true
        try {
          const payload = {
            Token: this.hubspotToken,
            Username: this.username,
            GroupID: 1,
          }
          await this.httpConnector.makeRequest('post', `${this.apiEndpoint}hubspotcontactimport?code=${this.apiCode}`, payload)
          this.$emit('on-success')
        } catch (error) {
          this.$emit('on-error')
        }
      },
    },
  }
</script>
<style scoped>
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgb(240, 196, 0);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.loaderWrapper {
  margin-top: 15px;
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
p {
  color: rgb(221, 155, 0);
  margin-top: 15px;
  font-weight: 600;
}
</style>
